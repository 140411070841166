@keyframes START-SENDDING {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes END-SENDING {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.start-sending {
    animation: 1s START-SENDING both;
}

.end-sending {
    animation: 1s START-SENDING both;
}