@keyframes ANIM-OPENING {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes ANIM-CLOSING {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translateX(80px);
    }
}