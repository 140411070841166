@keyframes SHOW-ERROR-FORM {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes HIDDE-ERROR-FORM {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.show-error {
    animation: SHOW-ERROR-FORM 1s both;
}

.hidde-error {
    animation: HIDDE-ERROR-FORM 0.3s both;
}